import {
  Pipe,
  PipeTransform,
  ChangeDetectorRef,
  Injector,
  Input,
} from '@angular/core';
import {
  LangChangeEvent,
  TranslateService,
  TranslationChangeEvent,
} from '@ngx-translate/core';
import { appSettings } from 'src/app/app.settings';
import { Subscription } from 'rxjs';
import { AppState } from '../state/app.state';

@Pipe({
  name: 'translatedValue',
  pure: false, // required to update the value when the promise is resolved
  standalone: true,
})
export class TranslatedValuePipe implements PipeTransform {
  private _value: string;

  private _onTranslationChange: Subscription;
  private _onLangChange: Subscription;
  private _onDefaultLangChange: Subscription;

  constructor(
    private _translateService: TranslateService,
    private _ref: ChangeDetectorRef,
    private _appState: AppState,
  ) {}

  public transform(translatedValue: any, tenantLanguage = false): string {
    if (!translatedValue) {
      return '-';
    }

    this.updateValue(translatedValue, tenantLanguage);
    this._dispose();

    // subscribe to onTranslationChange event, in case the translations change
    if (!this._onTranslationChange) {
      this._onTranslationChange =
        this._translateService.onTranslationChange.subscribe(
          (event: TranslationChangeEvent) => {
            if (event.lang === this._translateService.currentLang) {
              this.updateValue(translatedValue, tenantLanguage);
            }
          },
        );
    }

    // subscribe to onLangChange event, in case the language changes
    if (!this._onLangChange) {
      this._onLangChange = this._translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.updateValue(translatedValue, tenantLanguage);
        },
      );
    }

    // subscribe to onDefaultLangChange event, in case the default language changes
    if (!this._onDefaultLangChange) {
      this._onDefaultLangChange =
        this._translateService.onDefaultLangChange.subscribe(() => {
          this.updateValue(translatedValue, tenantLanguage);
        });
    }

    return this._value;
  }

  private _nullIfEmpty(value: string) {
    if (typeof value === 'string' && value.length > 0) {
      return value;
    }
    return null;
  }

  public updateValue(translatedValue: any, tenantLanguage: boolean): void {
    const defaultLang = appSettings.defaultLang.value;
    const appLanguage = this._translateService.currentLang;
    const displayLanguage = this._appState.userSettings.displayContentLanguage;

    if (Array.isArray(translatedValue)) {
      const priorityLanguage = tenantLanguage
        ? this._nullIfEmpty(
            translatedValue.find((x) => x.language === displayLanguage)?.value,
          ) ||
          this._nullIfEmpty(
            translatedValue.find((x) => x.language === appLanguage)?.value,
          )
        : this._nullIfEmpty(
            translatedValue.find((x) => x.language === appLanguage)?.value,
          ) ||
          this._nullIfEmpty(
            translatedValue.find((x) => x.language === displayLanguage)?.value,
          );

      this._value =
        priorityLanguage ||
        this._nullIfEmpty(
          translatedValue.find((x) => x.language === defaultLang)?.value,
        ) ||
        this._nullIfEmpty(
          translatedValue.filter((x) => x.value?.length > 0)[0]?.value,
        ) ||
        '-';
    } else {
      const priorityLanguage = tenantLanguage
        ? this._nullIfEmpty(translatedValue[displayLanguage]) ||
          this._nullIfEmpty(translatedValue[appLanguage])
        : this._nullIfEmpty(translatedValue[appLanguage]) ||
          this._nullIfEmpty(translatedValue[displayLanguage]);

      this._value =
        priorityLanguage ||
        this._nullIfEmpty(translatedValue[defaultLang]) ||
        this._nullIfEmpty(translatedValue[appLanguage]) ||
        this._nullIfEmpty(translatedValue[defaultLang]) ||
        this._nullIfEmpty(
          Object.values<string>(translatedValue)?.filter(
            (x: string) => x?.length > 0,
          )[0],
        ) ||
        '-';
    }

    this._ref.markForCheck();
  }

  public ngOnDestroy(): void {
    this._dispose();
  }

  /**
   * Clean any existing subscription to change events
   */
  private _dispose(): void {
    if (typeof this._onTranslationChange !== 'undefined') {
      this._onTranslationChange.unsubscribe();
      this._onTranslationChange = undefined;
    }
    if (typeof this._onLangChange !== 'undefined') {
      this._onLangChange.unsubscribe();
      this._onLangChange = undefined;
    }
    if (typeof this._onDefaultLangChange !== 'undefined') {
      this._onDefaultLangChange.unsubscribe();
      this._onDefaultLangChange = undefined;
    }
  }
}
