import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { UserAction, LxmAppModule } from 'src/app/enum';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ILanguage, LocaleService } from 'src/app/services/locale.service';
import { OverlayViewService } from 'src/app/services/overlay-view.service';
import { AppState } from 'src/app/state/app.state';
import { SelectComponent } from '../../../form/select/select.component';
import {
  JsonPipe,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
} from '@angular/common';
import { MatRipple } from '@angular/material/core';

@Component({
  selector: '[ov-menu-item]',
  templateUrl: './ov-menu-item.component.html',
  styleUrls: ['./ov-menu-item.component.scss'],
  standalone: true,
  imports: [
    SvgIconComponent,
    RouterLink,
    ReactiveFormsModule,
    SelectComponent,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgTemplateOutlet,
    JsonPipe,
  ],
  host: {
    class:
      'flex min-h-big cursor-pointer items-center gap-12x px-8x py-4x text-grey-100 hover:bg-veryLightPeach [&.selected]:bg-veryLightPeach [&.active]:bg-veryLightPeach', // [&.selected]:bg-[#f3f2f2]
  },
  hostDirectives: [
    {
      directive: MatRipple,
    },
  ],
})
export class OverlayViewMenuItemComponent {
  @Input() public icon: string;
  @Input() public label: string;

  constructor() {}
}
