import { computed, Injectable, signal } from '@angular/core';
import { OverlayViewComponent } from '../components/_layout/overlay-view/overlay-view.component';
import { DomComponentService } from './dom-component.service';

@Injectable({ providedIn: 'root' })
export class OverlayViewService {
  constructor(private _domComponentService: DomComponentService) {}

  public overlays = signal([]);
  public overlayCount = computed(() => this.overlays().length);
  public animationClosingIndex = signal(null);

  public add(content: IOverlayItem) {
    const { id, headerRef, contentRef, headerBorder, animation } = content;

    this.overlays.update((overlays) => {
      return [
        ...overlays,
        {
          id: id,
          headerRef: headerRef,
          contentRef: contentRef,
          componentRef: this._domComponentService.add(OverlayViewComponent, id),
          headerBorder: headerBorder,
          animation: animation,
        },
      ];
    });
  }

  public close(id) {
    const ref = this.overlays().find((x) => x.id === id);
    if (ref) {
      this._domComponentService.remove(ref.componentRef);
      this.overlays.update((overlays) => overlays.filter((x) => x.id !== id));
    }
  }

  public clear() {
    this.overlays().forEach((x) =>
      this._domComponentService.remove(x.componentRef),
    );
    this.overlays.update(() => []);
  }
}

export interface IOverlayItem {
  headerRef?: any;
  contentRef: any;
  componentRef?: any;
  id: any;
  headerBorder?: boolean;
  animation?: string; //left-to-right | bottom-to-top
}
