import {
  Component,
  OnInit,
  Input,
  Optional,
  HostBinding,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  FormGroup,
  ControlContainer,
  AbstractControl,
  ReactiveFormsModule,
  NgModel,
  FormsModule,
} from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    MatCheckboxModule,
  ],
})
export class CheckboxComponent implements OnInit {
  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public label: string | any;
  @Input() public readonly = false;

  @Input() public checked: boolean;
  @Output() public checkedChange = new EventEmitter();

  private _ref: AbstractControl;

  constructor(@Optional() private _controlContainer: ControlContainer) {}

  public onChange(change: MatCheckboxChange) {
    const { checked } = change || {};
    this.checkedChange.emit(checked);
  }

  public ngOnInit(): void {
    if (this.checked) {
    } else if (this.for) {
      if (
        this._controlContainer &&
        this._controlContainer.control instanceof FormGroup
      ) {
        this.formGroup = <FormGroup>this._controlContainer.control;
      }
      this._ref = this.formGroup.get(this.for);
    }
  }

  public get isChecked() {
    if (this.checked !== undefined) {
      return this.checked;
    }
    if (this.checked) {
      return this.checked[this.for];
    }
    if (this.for) {
      return this.formGroup.get(this.for)?.value;
    }
    return false;
  }

  public select(event: any) {
    if (this.readonly) return;
    if (this.checked) {
      this.checked[this.for] = event.target.checked;
    } else if (this._ref) {
      this._ref.setValue(event.target.checked);
    }
  }
}
