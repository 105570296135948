<div
  class="overlay-content {{ animation() }} flex flex-1 flex-col overflow-auto"
  [class.animation-closing]="
    overlayViewService.animationClosingIndex() === index
  "
  (click)="$event.stopImmediatePropagation()"
>
  <!-- HEADER -->
  <div
    class="flex min-h-15x {{
      content().headerBorder ? 'border-b border-b-grey-30' : ''
    }} px-8x py-6x"
  >
    <div
      class="relative flex flex-1 shrink-0 items-center gap-5x text-ellipsis pr-10"
    >
      <!-- HEADER CONTENT GOES HERE -->
      <ng-container [ngTemplateOutlet]="content().headerRef"></ng-container>
      <ng-content select="[header]"></ng-content>
      <!-- ACTIONS -->
      <div class="absolute right-0 flex justify-end">
        <button
          matRipple
          class="flex size-15x items-center justify-center"
          (click)="close()"
        >
          <svg-icon
            src="assets/icons/close.svg"
            [svgStyle]="{ 'width.rem': 1, 'height.rem': 1 }"
            viewBox="auto"
          ></svg-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="flex flex-1 flex-col overflow-auto px-8x py-5x">
    <ng-container [ngTemplateOutlet]="content().contentRef"></ng-container>
    <ng-content></ng-content>
  </div>
</div>
