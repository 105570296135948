import {
  Component,
  OnInit,
  Optional,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  HostBinding,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  ViewContainerRef,
  ApplicationRef,
  ElementRef,
  signal,
} from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ViewEncapsulation } from '@angular/core';
import {
  FormGroup,
  ControlContainer,
  ReactiveFormsModule,
  UntypedFormGroup,
  FormControl,
} from '@angular/forms';
import {
  DropdownPosition,
  NgLabelTemplateDirective,
  NgOptionTemplateDirective,
  NgSelectComponent,
  NgSelectConfig,
  NgSelectModule,
} from '@ng-select/ng-select';
import { HttpClient } from '@angular/common/http';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
} from 'rxjs/operators';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CompareWithFn } from '@ng-select/ng-select/lib/ng-select.component';
import { TranslatedValuePipe } from 'src/app/pipes/translated-value.pipe';
import { SvgIconComponent } from 'angular-svg-icon';
import {
  JsonPipe,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
} from '@angular/common';
import { OverlayViewService } from 'src/app/services/overlay-view.service';
import { InputComponent } from '../input/input.component';
import { AutofocusDirective } from 'src/app/directives/autofocus.directive';
import { OverlayViewMenuItemComponent } from '../../_layout/overlay-view/menu-item/ov-menu-item.component';
import { MatRippleModule } from '@angular/material/core';
import { CheckboxComponent } from '../checkbox/checkbox.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  providers: [TranslatedValuePipe],
  imports: [
    SvgIconComponent,
    TranslateModule,
    MatCheckbox,
    ReactiveFormsModule,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgTemplateOutlet,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
    NgSelectComponent,
    NgIf,
    InputComponent,
    AutofocusDirective,
    JsonPipe,
    OverlayViewMenuItemComponent,
    MatRippleModule,
    CheckboxComponent,
  ],
})
export class SelectComponent implements OnInit {
  @ViewChild('fullscreenHeaderTemplate', { static: false })
  fullscreenHeaderTemplate?: ElementRef;
  @ViewChild('fullscreenContentTemplate', { static: false })
  fullscreenContentTemplate?: ElementRef;

  // @HostBinding('style.width') @Input() public width = '100%';
  @HostBinding('class.readonly') @Input() public readonly = false;
  @HostBinding('class.disabled') @Input() public disabled = false;

  @ViewChild('select', { static: false }) public select: NgSelectComponent;

  @Output() change: EventEmitter<any> = new EventEmitter();

  @Input() close: Observable<void>;

  @Input() public options: any[] = [];
  @Input() public url: string;
  @Input() public acData: () => any | any;
  @Input() public acLabelFormat: (item: any) => string;
  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public placeholder: string;
  @Input() public searchable = true;
  @Input() public clearable = true;
  @Input() public multiple = false;
  @Input() public selectAllOption = false;
  @Input() public maxSelectedItems: number;
  @Input() public labelField: string = 'name';
  @Input() public labelIcon: string | undefined;
  @Input() public labelIconField: string | undefined;
  @Input() public labelIconFallback: string | undefined;
  @Input() public labelIconClass: string | undefined;
  @Input() public compareField: string;
  @Input() public additionalField: string;
  @Input() public dropdownPosition: DropdownPosition = 'auto';
  @Input() public closeOnSelect = true;
  @Input() public underLabel: string;
  @Input() public value: string;
  @Input() public virtualScroll = false;
  @Input() public hideSelected = false;
  @Input() public isTranslated = false;
  @Input() public sortByLabel = false;
  @Input() public sortByFn: (a: any, b: any) => number;
  @Input() public disableOption: (i: any) => boolean;
  @Input() public addTag: boolean | ((term: string) => any | Promise<any>) =
    false;
  @Input() public addTagText: string;
  @Input() public groupBy: any;
  @Input() public minTermLength: number = 2;
  @Input() public loading = false;
  @Input() public labelFn: (i: any) => string;
  @Input() public classFn: (i: any) => string;

  @Input() public label: string;

  @Input() public fullscreen = true;

  @Input() public compareWith: CompareWithFn = (a: any, b: any): boolean => {
    const field = this.value || this.compareField;
    if (field && (a.hasOwnProperty(field) || b.hasOwnProperty(field))) {
      return a[field] === b[field] || a[field] === b || a === b[field];
    }
    return JSON.stringify(a) === JSON.stringify(b);
  };

  public controls = new FormGroup({
    searchTerm: new FormControl(null),
  });

  public notFoundTranslated: string;
  public typeToSearchTranslated: string;
  public loadingTranslated: string;

  public finalizedOptions: any[];
  public filteredOptions = signal([]);

  // Turn on virtual scroll after specified options count
  // Note: Makes performance worse with small datasets
  public virutalScrollTurnOnPoint = 150;

  public selected: any;
  public overlay = false;
  public hasValue: boolean;

  public acRes: Observable<any>;
  public acSubject = new Subject<string>();

  private _langChangeSubscription: Subscription;
  private _valueChangeSubscription: Subscription;

  private _scrollParent: HTMLElement;

  public isOpenSubject = new BehaviorSubject(false);

  constructor(
    private _config: NgSelectConfig,
    private _el: ElementRef,
    private _http: HttpClient,
    private _translateService: TranslateService,
    private _translatedValuePipe: TranslatedValuePipe,
    private _cd: ChangeDetectorRef,
    private _overlayViewService: OverlayViewService,
    @Optional() private _controlContainer: ControlContainer,
  ) {
    this._onParentScroll = this._onParentScroll.bind(this);
  }

  public get ref() {
    return this.formGroup.controls[this.for];
  }

  public get isAutocomplete(): boolean {
    return this.url ? true : false;
  }

  public getItemClass(item: any) {
    if (this.classFn) {
      return this.classFn(item);
    }

    return null;
  }

  public getItemValue(item: any) {
    if (this.disableOption) {
      item.disabled = this.disableOption(item);
    }

    if (this.labelFn) {
      return this.labelFn(item);
    }

    if (this.isTranslated) {
      if (item[this.labelField]) {
        return this._translatedValuePipe.transform(item[this.labelField]);
      }
      return '';
    }

    if (this.labelField) {
      return item[this.labelField];
    }

    return item;
  }

  public onSelectAll(): void {
    let toSelect = this.options;
    if (this.value) {
      toSelect = toSelect.map((o) => o[this.value]);
    }
    this.formGroup.get(this.for).patchValue(toSelect);
  }

  public onClearAll(): void {
    this.formGroup.get(this.for).patchValue([]);
  }

  public getAdditionalValue(item: any): any {
    return new Function('_', 'return _.' + this.additionalField)(item);
  }

  public _finalizeOptions(): void {
    let finalizedOptions = this._getTranslatedOptions(
      this.options,
      this.labelField,
      this.isTranslated,
    );
    if (this.sortByLabel) {
      const fn =
        this.sortByFn ||
        ((a: any, b: any) =>
          this.getItemValue(a) > this.getItemValue(b) ? 1 : -1);
      finalizedOptions = finalizedOptions?.sort(fn);
    }

    this.finalizedOptions = finalizedOptions?.filter((x) =>
      this.getItemValue(x),
    );

    this.filteredOptions.set([...this.finalizedOptions]);

    console.log(this.finalizedOptions, this.filteredOptions());

    if (this.finalizedOptions?.length > this.virutalScrollTurnOnPoint) {
      this.virtualScroll = true;
    } else {
      this.virtualScroll = false;
    }
  }

  private _getTranslatedOptions(
    items: any[],
    labelField: string,
    isTranslated: boolean,
  ): any[] {
    if (!isTranslated) {
      return items?.map((x) => {
        const clone = { ...x };

        const label = x[labelField];
        clone[labelField] =
          label == null ? '' : this._translateService.instant(label);
        return clone;
      });
    }
    return items;
  }

  private _translateLabels(): void {
    this._translateService
      .get('components.select.type_to_search_text', {
        number: this.minTermLength,
      })
      .subscribe((translated: string) => {
        this.typeToSearchTranslated = translated;
      });
    this._translateService
      .get('components.select.not_found_text')
      .subscribe((translated: string) => {
        this.notFoundTranslated = translated;
      });
    this._translateService
      .get('components.select.loading')
      .subscribe((translated: string) => {
        this.loadingTranslated = translated;
      });
  }

  public onChange(value: any): void {
    if (!this.url && !this.multiple) {
      this.select.blur();
    }
    this.change.emit(value);
  }

  private _openFullscreenSelect() {
    this._overlayViewService.add({
      headerRef: this.fullscreenHeaderTemplate,
      contentRef: this.fullscreenContentTemplate,
      id: 'select',
    });
  }

  private _closeFullscreenSelect() {
    this._overlayViewService.close('select');
  }

  public onOpen() {
    this.isOpenSubject.next(true);

    if (!this.fullscreen) {
      this._scrollParent = this.getScrollParent(this._el.nativeElement, false);
      this._scrollParent.addEventListener('scroll', this._onParentScroll);
    }
  }

  public onClose() {
    this.isOpenSubject.next(false);

    if (!this.fullscreen) {
      if (this.url) {
        this.options = [];
      }

      if (this._scrollParent) {
        this._scrollParent.removeEventListener('scroll', this._onParentScroll);
      }
    }
  }

  public isOptionSelected(option) {
    if (!option) return false;

    if (!this.ref.value) {
      return false;
    }

    if (this.multiple) {
      // if (!this.ref.value?.length) {
      //   return false;
      // }

      return this.ref.value?.some((x) => this.compareWith(option, x));
    }

    return this.compareWith(option, this.ref.value);
  }

  public handleOptionClick(option): void {
    console.log(option);
    if (!option) return;

    if (option.function && typeof option.function === 'function') {
      option.function();
    }

    if (this.ref && option) {
      const sanitizedOption = Object.assign({}, option);

      if (option.function) {
        delete sanitizedOption.function;
      }

      if (option.shouldClose) {
        delete sanitizedOption.shouldClose;
      }

      let opt = this.value ? sanitizedOption[this.value] : sanitizedOption;
      let toSet = opt;

      if (this.multiple) {
        let arr = Array.isArray(this.ref.value) ? this.ref.value : [];

        const selectedIndex = arr.findIndex((x) => this.compareWith(opt, x));
        if (selectedIndex > -1) {
          arr.splice(selectedIndex, 1);
          toSet = [...arr];
        } else {
          toSet = [...arr, opt];
        }
      }

      this.ref.setValue(toSet);
      this.change.emit(sanitizedOption);
    }

    if (this.multiple || !this.closeOnSelect || option.shouldClose === false) {
      return;
    }

    this.select.close();
  }

  // SEARCH
  public onSearch(term: string) {
    this.filteredOptions.update((options) => {
      return [
        ...this.finalizedOptions.filter((option) => this.search(term, option)),
      ];
    });
  }

  public search = (term: string, item: any) => {
    if (this.url || !term) {
      return true;
    }

    if (this.additionalField) {
      return (
        this.getItemValue(item).toLowerCase().indexOf(term.toLowerCase()) >
          -1 ||
        this.getAdditionalValue(item)
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1
      );
    }

    var val = this.getItemValue(item);
    if (!val) {
      return false;
    }

    return val.toLowerCase().indexOf(term.toLowerCase()) > -1;
  };

  public find(search: { term: string; items: any[] }) {
    if (!this.url) {
      return;
    }

    if (search.term?.length < this.minTermLength) {
      this.options = [];
      return;
    }

    this.acSubject.next(search.term);
  }

  //

  private _updateHasValue(x: any) {
    const isArray = (o) => o instanceof Array;
    this.hasValue =
      x !== undefined &&
      x !== null &&
      ((isArray(x) && x.length > 0) || (!isArray(x) && x !== ''));
  }

  // CLOSE DROPDOWN PANEL ON SCROLL

  public getScrollParent(element, includeHidden) {
    var style = getComputedStyle(element);
    var excludeStaticParent = style.position === 'absolute';
    var overflowRegex = includeHidden
      ? /(auto|scroll|hidden)/
      : /(auto|scroll)/;

    if (style.position === 'fixed') return document.body;
    for (var parent = element; (parent = parent.parentElement); ) {
      style = getComputedStyle(parent);
      if (excludeStaticParent && style.position === 'static') {
        continue;
      }
      if (
        overflowRegex.test(style.overflow + style.overflowY + style.overflowX)
      )
        return parent;
    }

    return document.body;
  }

  private _onParentScroll() {
    this.select.close();
  }

  //

  // LIFECYCLE

  public ngOnInit() {
    this._translateLabels();
    this._finalizeOptions();
    this._langChangeSubscription =
      this._translateService.onLangChange.subscribe(() => {
        this._translateLabels();
        this._finalizeOptions();
      });

    if (
      this._controlContainer &&
      this._controlContainer.control instanceof FormGroup
    ) {
      this.formGroup = <FormGroup>this._controlContainer.control;

      if (this.for) {
        const ref = this.formGroup.get(this.for);

        if (ref) {
          this.selected = ref.value;
          this._updateHasValue(this.selected);

          this._valueChangeSubscription = ref.valueChanges.subscribe((x) => {
            this._updateHasValue(x);
          });
        } else {
          console.error(`No FormGroup reference for ${this.for}.`);
        }
      }
    }

    if (this.url) {
      const _this = this;
      this.acRes = this.acSubject.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map((term) => {
          if (term === null) {
            this.loading = false;
            return null;
          }

          this.loading = true;
          if (_this.acData) {
            const data =
              typeof _this.acData === 'function'
                ? _this.acData()
                : _this.acData;
            return _this._http.post<any[]>(_this.url + term, data);
          } else {
            return _this._http.get<any[]>(_this.url + term);
          }
        }),
      );

      this.acRes.subscribe((o) => {
        if (o === null) {
          this.loading = false;
          return;
        }

        o.subscribe((res: any[]) => {
          if (_this.acLabelFormat) {
            _this.options = res.map((x) =>
              Object.assign(x, { [_this.labelField]: _this.acLabelFormat(x) }),
            );
          } else {
            _this.options = res;
          }
          this.loading = false;
          this._cd.markForCheck();
        });
      });
    }
  }

  ngAfterViewInit() {
    // if (!this.groupBy && this.compareWith) {
    //   this.select.compareWith = this.compareWith.bind(this);
    // }

    // Overwrite open and close functions for fullscreen select
    if (this.select && this.fullscreen) {
      this.select.open = this._openFullscreenSelect.bind(this);
      this.select.close = this._closeFullscreenSelect.bind(this);
      this.select.select = this.handleOptionClick.bind(this);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options']) {
      if (!changes['options']?.firstChange) {
        this._finalizeOptions();
      }
    }
  }

  ngOnDestroy() {
    if (this._langChangeSubscription) {
      this._langChangeSubscription.unsubscribe();
    }
    if (this._valueChangeSubscription) {
      this._valueChangeSubscription.unsubscribe();
    }
  }
}
