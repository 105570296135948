import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[autofocus]',
  standalone: true,
})
export class AutofocusDirective {
  constructor(private el: ElementRef) {}

  public ngAfterViewInit(): void {
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 500);
  }
}
