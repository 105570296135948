@if (!noLabel) {
  <label class="flex" [for]="elementId" [class.required]="isRequired">
    @if (label) {
      <!-- LABEL -->
      <span
        class="font-display text-xs font-medium text-grey-100"
        [innerHTML]="label"
      ></span>
      <!-- REQUIRED -->
      @if (required) {
        <span class="font-display text-xs font-medium text-rustyOrange">*</span>
      }
    }
  </label>
}
<div class="form-field-content flex flex-col">
  <ng-content></ng-content>
  <ng-content select="hint"></ng-content>
  @if (for) {
    <validation-errors [for]="for"></validation-errors>
  }
</div>
