import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[readonly]'
})
export class ReadonlyDirective {

  private _readonly: boolean = false;

  @Input() public set readonly(readonly: boolean) {
    this._readonly = readonly;
  }

  public get readonly() {
    return this._readonly;
  }

}
