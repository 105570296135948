<ng-container [formGroup]="formGroup">
  <ng-select
    #select
    [class.autocomplete]="url"
    [class.has-value]="hasValue"
    [class.underlabel]="underLabel"
    [formControlName]="for"
    [searchFn]="search"
    appendTo="app-root"
    [loading]="loading"
    [typeahead]="url ? acSubject : null"
    [items]="url ? options : finalizedOptions"
    [bindValue]="value"
    [bindLabel]="labelField"
    [searchable]="!fullscreen && readonly !== true && (searchable || url)"
    [labelForId]="for"
    [addTag]="addTag"
    [addTagText]="addTagText"
    [multiple]="multiple"
    [maxSelectedItems]="maxSelectedItems"
    [clearSearchOnAdd]="true"
    [hideSelected]="hideSelected"
    [placeholder]="placeholder | translate"
    [class.disabled]="disabled"
    [groupBy]="groupBy"
    [virtualScroll]="virtualScroll"
    [clearable]="clearable"
    [minTermLength]="minTermLength"
    [typeToSearchText]="typeToSearchTranslated"
    [notFoundText]="notFoundTranslated"
    [loadingText]="loadingTranslated"
    (search)="find($event)"
    (open)="onOpen()"
    (close)="onClose()"
    (change)="onChange($event)"
    [dropdownPosition]="dropdownPosition"
    [closeOnSelect]="multiple ? false : closeOnSelect"
  >
    <ng-template ng-header-tmp>
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </ng-template>

    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <ng-container
        *ngTemplateOutlet="labelTemplate; context: { item: item, clear: clear }"
      ></ng-container>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-item$="item$">
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: { item: item, item$: item$ }
        "
      ></ng-container>
    </ng-template>
  </ng-select>
</ng-container>

<ng-template #labelTemplate let-item="item" let-clear="clear">
  <!-- MULTISELECT LABEL -->
  <ng-container [ngSwitch]="multiple">
    <ng-container *ngSwitchCase="true">
      <div
        [class]="
          'flex h-9x items-center justify-center gap-5x rounded-sm bg-grey-10 px-[0.3125rem] py-1x' +
          getItemClass(item)
        "
      >
        <div class="ng-value-label">
          <div class="flex items-center">
            @if (labelIconField) {
              <svg-icon
                class="mr10 {{ labelIconClass }}"
                [name]="item[labelIconField]?.toLowerCase()"
                [svgStyle]="{ 'width.rem': 1, 'height.rem': 1 }"
              ></svg-icon>
            }

            <span>{{ getItemValue(item) | translate }}</span>
            <span class="pro-icon ml-1" *ngIf="item.pro"></span>
          </div>
          <small *ngIf="additionalField">
            {{ getAdditionalValue(item) }}
          </small>
        </div>
        <span
          class="ng-value-icon right"
          (click)="clear(item)"
          aria-hidden="true"
          >×</span
        >
      </div>
    </ng-container>

    <!-- SINGLE SELECT LABEL -->
    <ng-container *ngSwitchDefault>
      <div [class]="'flex items-center ' + getItemClass(item)">
        <span class="ng-value-label">
          <div class="flex items-center">
            @if (labelIconField) {
              <svg-icon
                class="mr-2 {{ labelIconClass }}"
                [name]="item[labelIconField]?.toLowerCase()"
                [svgStyle]="{ 'width.rem': 1, 'height.rem': 1 }"
              ></svg-icon>
            }
            <span
              class="app-select-label-value"
              [class.label-bold]="underLabel"
              >{{ getItemValue(item) | translate }}</span
            >
            @if (item.pro) {
              <span class="pro-icon ml-1"></span>
            }
          </div>
          @if (underLabel) {
            <div class="text-grey-alt2 font-text text-xs font-normal">
              <small>{{ underLabel | translate }}</small>
            </div>
          }
        </span>
        <small *ngIf="additionalField">
          {{ getAdditionalValue(item) }}
        </small>
        <span
          class="ng-value-icon right"
          (click)="clear(item)"
          aria-hidden="true"
          >×</span
        >
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #optionTemplate let-item="item" let-item$="item$">
  <ng-container [ngSwitch]="multiple">
    <ng-container *ngSwitchDefault>
      <div [class]="'ng-value-wrapper default ' + getItemClass(item)">
        <span class="ng-value-label">
          <span class="flex items-center">
            @if (labelIconField) {
              <svg-icon
                class="mr-2 {{ labelIconClass }}"
                [name]="item[labelIconField]?.toLowerCase()"
                [svgStyle]="{ 'width.rem': 1, 'height.rem': 1 }"
              ></svg-icon>
            }
            <span class="flex items-center">
              {{ getItemValue(item) }}
              <span class="pro-icon ml-1" *ngIf="item.pro"></span>
            </span>
          </span>
        </span>
        <small *ngIf="additionalField">
          {{ getAdditionalValue(item) }}
        </small>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="true">
      <mat-checkbox
        class="multi-checkbox"
        [checked]="item$.selected"
        [class.has-additional-field]="additionalField"
      >
        <span class="inline-flex items-center">
          {{ getItemValue(item) }}
          <span class="pro-icon ml-1" *ngIf="item.pro"></span>
        </span>
        <small *ngIf="additionalField">
          <br />
          {{ getAdditionalValue(item) }}
        </small>
      </mat-checkbox>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #headerTemplate>
  <div class="header-tmp-wrapper" (click)="select.close()">
    <div class="row" *ngIf="selectAllOption">
      <button class="select-select-all" (click)="onSelectAll()">
        {{ "components.select.select_all" | translate }}
      </button>
      <button class="select-clear-all" (click)="onClearAll()">
        {{ "components.select.clear_all" | translate }}
      </button>
    </div>
    <ng-content select="[header]"></ng-content>
  </div>
</ng-template>

<!-- @let iconClasses = "flex size-15x items-center justify-center";

<ng-template #fullscreenHeaderTemplate>
  <div [class]="iconClasses">
    <svg-icon src="assets/icons/menu/menu-search.svg"></svg-icon>
  </div>
  <input
    class="flex flex-1 font-display text-sm font-bold text-grey-100 outline-none"
    placeholder="Otsi"
    autofocus
  />
  
</ng-template> -->

<ng-template #fullscreenHeaderTemplate>
  <div class="flex size-15x items-center justify-center">
    <svg-icon src="assets/icons/menu/menu-search.svg"></svg-icon>
  </div>
  <app-input
    class="flex flex-1 border-none"
    [formGroup]="controls"
    for="searchTerm"
    autofocus
    (onValueChange)="onSearch($event)"
    [placeholder]="label | translate"
  ></app-input>
</ng-template>

<ng-template #fullscreenContentTemplate>
  <div class="flex flex-col">
    @let o = filteredOptions();
    @if (o.length > 0) {
      @for (option of o; track $index) {
        <!-- <ng-container *ngTemplateOutlet="optionTemplate; context: { item: option }"></ng-container> -->

        @let isSelected = isOptionSelected(option);
        <div
          ov-menu-item
          (click)="handleOptionClick(option)"
          [class.selected]="isSelected"
        >
          <div class="flex items-center">
            @if (multiple) {
              <app-checkbox [checked]="isSelected"></app-checkbox>
            }

            @if (labelIconField) {
              <svg-icon
                class="mr-2 {{ labelIconClass }}"
                [name]="option[labelIconField]?.toLowerCase()"
                [svgStyle]="{ 'width.rem': 1, 'height.rem': 1 }"
              ></svg-icon>
            }

            {{ getItemValue(option) | translate }}
          </div>
          <div>
            <span
              class="select-fullscreen-option__icon tick-active-icon"
            ></span>
          </div>
        </div>
      }
    }
  </div>
</ng-template>
