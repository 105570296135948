import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Host,
  HostBinding,
  HostListener,
  Input,
  Output,
  signal,
} from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { SvgIconComponent } from 'angular-svg-icon';
import {
  IOverlayItem,
  OverlayViewService,
} from 'src/app/services/overlay-view.service';

@Component({
  selector: 'app-overlay-view',
  templateUrl: './overlay-view.component.html',
  styleUrls: ['./overlay-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, SvgIconComponent, MatRipple],
})
export class OverlayViewComponent {
  @Input() public id: any;

  @Output() public onClose: EventEmitter<any> = new EventEmitter();

  public content = signal<IOverlayItem>(null);
  public animation = signal(null);
  public index = 0;

  public animationCloseDuration = 100;

  constructor(public overlayViewService: OverlayViewService) {}

  @HostBinding('class.show-underlay') public showUnderlay = computed(
    () =>
      this.overlayViewService.animationClosingIndex() !== this.index ||
      this.overlayViewService.overlayCount() === 1,
  );

  // publicget showUnderlay() {
  //   const closingIndex = this.overlayViewService.animationClosingIndex();
  //   if (closingIndex - 1 === this.index) {
  //     return true;
  //   }

  //   const overlayCount = this.overlayViewService.overlayCount();
  //   return overlayCount - 1 === this.index;
  // }

  @HostListener('click') public onClick() {
    this.close();
  }

  public close() {
    if (this.animation) {
      this.overlayViewService.animationClosingIndex.set(this.index);
      setTimeout((_) => {
        this.overlayViewService.close(this.id);
        this.onClose.emit(this.id);
        this.overlayViewService.animationClosingIndex.set(null);
      }, this.animationCloseDuration);
    }
  }

  ngOnInit() {
    if (this.id) {
      const overlays = this.overlayViewService.overlays();
      const overlayIndex = overlays.findIndex((x) => x.id === this.id);
      const ref = overlays[overlayIndex];

      if (ref) {
        const { animation } = ref;
        this.content.set(ref);
        this.index = overlayIndex;

        if (animation) {
          this.animation.set(animation);
        } else {
          if (this.overlayViewService.overlayCount() < 2) {
            this.animation.set('bottom-to-top');
          } else {
            this.animation.set('left-to-right');
          }
        }
      }
    }
  }
}
