import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  Optional,
  Host,
  OnInit,
  SimpleChanges,
  HostBinding,
} from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
// import { ScopeComponent } from '../../_layout/card/card.component';
import {
  FormGroup,
  ControlContainer,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ValidationErrorsComponent } from '../validation-errors/validation-errors.component';
import { ReadonlyDirective } from 'src/app/directives/readonly.directive';

@Component({
  selector: 'form-field',
  standalone: true,
  templateUrl: './form-field.component.html',
  styleUrls: ['form-field.component.scss'],
  imports: [
    CommonModule,
    ValidationErrorsComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
  // encapsulation: ViewEncapsulation.None
})
export class FormFieldComponent implements OnInit {
  @Input() public formGroup?: FormGroup;
  @Input() public for?: string;
  @Input() public label?: string;
  @Input() public labelPosition = 'top';
  @Input() public type?: string;
  @Input() public required: boolean = false;
  @Input() public readonly = false;
  @Input() public noLabel = false;
  @Input() public inputWidth = '100%';
  @Input() public inputClass?: string;
  @Input() public maxlength?: string;
  @Input() public minRows?: string;

  @Input() public description?: string;

  @Input() public position: string = 'top';
  @Input() public trigger: string = 'click';

  //   public translationsPath: string;

  @Input() public elementPrefix?: string;

  public formGroupDisabled = false;

  public labelKey?: string;
  public descriptionKey?: string;

  public labelTranslated?: string;
  public descriptionTranslated?: string;

  public showDescription = false;

  public get form(): FormGroup {
    return this.formGroup as any;
  }

  @HostBinding('class') public get hostClasses() {
    return `label--${this.labelPosition}`;
  }

  constructor(
    // @Optional() @Host() private _scope: ScopeComponent,
    @Optional() @Host() private _readonlyQuery: ReadonlyDirective,
    @Optional() private _controlContainer: ControlContainer,
    private _translateService: TranslateService,
  ) {}

  public elementId?: string;

  public get isInput() {
    return (
      this.type &&
      [
        'input-text',
        'input-password',
        'input-number',
        'input-tel',
        'input-email',
      ].indexOf(this.type) >= 0
    );
  }

  public get isTextarea() {
    return this.type === 'textarea';
  }

  public get control() {
    if (!this.formGroup || !this.for) {
      return undefined;
    }

    return this.formGroup.get(this.for) as FormControl;
  }

  public get errors() {
    if (!this.control) {
      return [];
    }
    return this.control.errors;
  }

  public get inputType() {
    return this.type?.replace('input-', '');
  }

  public get isRequired() {
    return this.required === true;
  }

  public ngOnInit(): void {
    if (this._readonlyQuery && this._readonlyQuery.readonly) {
      this.readonly = this._readonlyQuery.readonly;
    }

    if (
      this._controlContainer &&
      this._controlContainer.control instanceof FormGroup
    ) {
      this.formGroup = this._controlContainer.control;

      if (this.elementPrefix === undefined) {
        let path = this._controlContainer.path?.reverse();
        this.elementPrefix = path?.join('_');
      }

      if (this.elementPrefix) {
        this.elementId = `${this.elementPrefix}_${this.for}`;
      } else {
        this.elementId = this.for;
      }
    }
  }
}
