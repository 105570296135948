@if (icon) {
  <svg-icon
    class="size-15x"
    [src]="icon"
    [svgStyle]="{ width: 'inherit', height: 'inherit' }"
    viewBox="auto"
  ></svg-icon>
}

@if (label) {
  <span class="font-display text-sm text-grey-100">{{ label }}</span>
}
<ng-content></ng-content>
